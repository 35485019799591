import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import delivery_truck from '../Assets/delivery_truck.png';
import schedule from '../Assets/schedule.png';
import we_clean from '../Assets/weClean.png';

const StyledBox = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 600,
    marginTop: '120px',
    paddingTop: '24px',
    borderColor: theme.palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: ``,
    backgroundSize: 'cover',
    transform: 'scaleX(-1)',
  }));



const items = [
    {
        icon: <img width="200px" src={schedule} alt={'You Schedule'}/>,
        title: 'You Schedule',
        description: ['Pick a day and frequency that works for you with just a few clicks on our website.']
    },
    {
        icon: <img width="200px" src={delivery_truck} alt={'We Pick Up'}/>,
        title: 'We Pick Up',
        description: ['Our friendly team collects your laundry from your doorstep - no need to leave the house.']
    },
    {
        icon: <img width="200px" src={we_clean} alt={'We Clean'}/>,
        title: 'We Clean',
        description: ['Using premium detergents we wash, dry, and fold your clothes with care.']
    },
    {
        icon: <img width="200px" src={delivery_truck} alt={'We Deliver'}/>,
        title: 'We Deliver',
        description: ['In no time, your fresh, neatly folded laundry, is delivered back to your door.']
    }
];

const Services = (props: {}) => {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)
    return (
        <Container id="Services" sx={{ py: {xs: 8, sm: 16}}}>
            <Box 
                sx={{
                    margin: '-40px auto',
                    width: { xs: '100%', md: '60%'}
                }}
            >
                <Typography
                    component='h2'
                    variant='h4'
                    gutterBottom
                    sx={{ fontFamily: 'Montserrat, sans serif', fontWeight: '400',textAlign: 'center', width: '100%', fontSize: '36px', color: '#3a3a3a', margin: '0 auto'}}
                >
                    Our Services
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ 
                        color: '#3a3a3a',
                        mb: { xs: 4 },
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: 400,
                        textAlign: 'center',
                    }}
                >      
                Tired of laundry taking over your week? Let Limitless Laundry take the hassle off your hands! With our convenient pickup and delivery service, we collect your laundry, clean it with care using eco-friendly detergents, and deliver it back fresh and neatly folded—fast and hassle-free. Enjoy free pickup and delivery, premium cleaning, and more time for the things you love. Book your first service today and get 10% OFF!
                </Typography>
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: { xs: 'column'},
                    gap: 2
                }}
            >
                <div>
                    <Box
                        sx={{
                            display: { xs: 'flex'},
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {items.map((({icon, title, description}, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        p: 2,
                                        height: '100%',
                                        alignSelf: 'center',
                                        marginTop: '24px',
                                        width: {xs: '100%', md: '60%'}
                                    }}
                                >
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        gap: 1,
                                        textAlign: 'left',
                                        textTransform: 'none',
                                        color: '#3a3a3a'
                                    }}>
                                        
                                        <Typography sx={{
                                            fontSize: '32px',
                                            fontFamily: 'Lilita One, sans serif',
                                        }}>{title}</Typography>
                                        <div style={{
                                            width: '90%',
                                            borderBottom: '1px solid #202020'
                                        }} />
                                        <Box 
                                            sx={{
                                                display: 'flex',
                                                flexDirection: {xs: 'row',md: index%2 ? 'row-reverse' : 'row'},
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {icon}
                                            <Box 
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                height: '225px'
                                            }}>
                                            {description.map((text, i) => {
                                                return (
                                                    <Box key={i}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            height: '40px'
                                                        }}>
                                                        <Typography variant="body2" sx={{
                                                            ml: '20px',
                                                            fontFamily: 'Montserrat, sans serif',
                                                            fontWieght: 400,
                                                        }}>{text}</Typography>
                                                    </Box>
                                                )
                                            })}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            );
                        }))}
                    </Box>
                </div>
            </Box>
        </Container>
    );
}

export default Services;