import * as React from 'react';
import { Container, Box, Typography} from '@mui/material';

import customer from '../Assets/customer.png';
import reliable from '../Assets/reliable.png';
import delivery_truck from '../Assets/delivery_truck.png'

const items = [
    {
        icon: customer,
        title: 'Family Focused Freedom',
        detail: 'We believe that time spent with loved ones is priceless. Our goal is to take the burden of laundry off your hands so you can focus on creating meaningful moments with your family and friends.',
    },
    {
        icon: delivery_truck,
        title: 'Trusted Reliability',
        detail: 'Your peace of mind matters to us. From pickup to delivery, we ensure that your laundry is handled with care, so you can trust us to make your life easier and stress-free.',
    },
    {
        icon: reliable,
        title: 'Simplicity and Convenience',
        detail: 'Life can be complicated, but laundry shouldn’t be. Our seamless process is designed to simplify your routine, giving you back valuable time for the things you enjoy most.',
    }
]
// const items = [
//     { 
//         icon: reliable, 
//         title: 'Reliable, Hassle-Free Service', 
//         detail: 'We pride ourselves on providing consistent and dependable service you can count on. Our team follows a precise schedule, and each visit includes a thorough sweep of your yard to ensure no mess is left behind. We communicate clearly, updating you with photos and messages so you know exactly when the job is done and your gate is securely closed.'},
//     { 
//         icon: clean, 
//         title: 'Commitment to Health and Safety', 
//         detail: 'Your family’s health and safety are our top priorities, which is why we sanitize our tools and footwear between every visit. This prevents cross-contamination between homes, giving you peace of mind that your yard stays clean and safe. We also use eco-friendly disposal methods to reduce our environmental impact, prioritizing your pet’s safety and the health of our shared community.'
//     },
//     { 
//         icon: customer, 
//         title: 'Exceptional Customer Care', 
//         detail: 'Our team is dedicated to delivering excellent customer service, with flexible scheduling options to fit your lifestyle. Whether you need weekly, bi-weekly, or custom service, we’re here to make it convenient for you. If you have any questions or special requests, we’re just a call or message away, ensuring that you receive friendly, responsive care every time.'
//     }
// ];

// const keyItems = [
//     {
//         icon: vettedEmployee,
//         title: 'Quality Employees',
//         detail: 'We ensure that our employees are of the highest quality through background checks, training, and their commitment to improving the lives of our clients and their pets.',
//     },
//     {
//         icon: petSafety,
//         title: 'Pet Safety',
//         detail: 'We sanitize and clean all of our equipment and shoes after each job, killing any viruses and bacteria, to protect the health and safety of your pet.',
//     },
//     {
//         icon: customerPortal,
//         title: 'Customer Portal',
//         detail: 'We have a customer portal to allow customers to optimize their experience. We are commited to saving you time so that you can spend it more where it matters.',
//     },
//     {
//         icon: contractFree,
//         title: 'Contract Free',
//         detail: 'We do not require any contract commitment to sign up. You choose whether or have a service for a month or 2 years, feel free to cancel at any time.',
//     },
//     {
//         icon: gatePhoto,
//         title: 'Service Complete Photos',
//         detail: 'Upon completion of a clean up, we will text you an image of your locked and secured gate, giving you the peace of mind knowing your loved ones are secure.',
//     },
//     {
//         icon: textUpdates,
//         title: 'Text Updates',
//         detail: 'We will update you with up coming deals, appointment days, and even when we are enroute to your home.',
//     },
// ];
const About = (props: {}) => {
    return (
       <Container id="About"
            sx={{
                py: { xs: 8, sm: 16},
                paddingTop: '100px',
                marginTop: '-100px',
            }}
       >
            <Box
                sx={{
                    margin: '60px auto',
                    width: { xs: '100%', md: '60%'} 
                }}
            >
              {/*  <Typography
                    sx={{
                        fontFamily: 'Montserrat, san serif',
                        fontWeight: 400,
                        fontSize: '32px',
                        textAlign: 'center'
                    }}
                >
                    Why Choose Outscooped
                </Typography> */}
                {/* {keyItems.map(({icon, title, detail}, index) => {
                    return (
                        <Box 
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '80%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '36px auto',
                            }}
                        >
                            <img width='40px' height='40px' src={icon} alt={title} style={{ alignSelf: 'flex-start'}}/>
                            <Box
                                sx={{
                                    marginLeft: '24px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Lilita One, san serif',
                                        fontWeight: 400,
                                        fontSize: '28px',
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat, san serif',
                                        fontWeight: 300,
                                        fontSize: '14px',
                                    }}
                                >
                                    {detail}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })} */}
            {/* </Box>
            <Box
                sx={{
                    margin: '-40px auto',
                    width: { xs: '100%', md: '60%'}
                }}
            > */}
                <Typography
                    sx={{
                        fontFamily: 'Montserrat, san serif',
                        fontWeight: 400,
                        fontSize: '32px',
                        textAlign: 'center',
                        marginTop: '100px',
                    }}
                >
                    Our Company Promise to You
                </Typography>
                <Typography
                sx={{
                    fontFamily: 'Montserrat, san serif',
                    fontWeight: 400,
                    fontSize: '18px',
                    textAlign: 'center',
                }}
                >
                Our vision is to give our customers more time to focus on what matters most by providing exceptional laundry care that is seamless, reliable, and focused on giving our customers more time.
                </Typography>
                {items.map(({icon, title, detail}, index) => {
                    return (
                        <Box 
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '80%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '36px auto',
                            }}
                        >
                            <img width='60px' height='60px' src={icon} alt={title} style={{ alignSelf: 'flex-start'}}/>
                            <Box
                                sx={{
                                    marginLeft: '24px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Lilita One, san serif',
                                        fontWeight: 400,
                                        fontSize: '28px',
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Montserrat, san serif',
                                        fontWeight: 300,
                                        fontSize: '14px',
                                    }}
                                >
                                    {detail}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
       </Container>
    )
}

export default About;