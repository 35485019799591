import * as React from 'react';
import { Box, Container, Typography, Stack, InputLabel, Link, TextField, Button } from '@mui/material';
import {styled} from '@mui/material/styles';
import heroImage from '../Assets/heroImage2.png';
import logo from '../Assets/logo no typography.png';

const StyledBox = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    width: '100%',
    height: 600,
    marginTop: '120px',
    paddingTop: '24px',
    borderColor: theme.palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
  }));

const Hero = (props:{}) => {
    return (
        <>
        <Box
            id="hero"
            sx={(theme) => ({
            width: '100%',
            display: 'flex',
            flexDirection: {xs: 'column-reverse', md:'row'},
            })}
        >
            <StyledBox id="image" 
            sx={{ 
                width: {xs: '100%', md:'50%'},
                backgroundPosition: {xs: '-200px center', md: 'center'}
            }} />
                
            <Box
                   
                        sx={{
                            marginTop: {xs: '120px', md: '120px'},
                            width: { xs:'100%', md: '50%'},
                            paddingTop: '24px',
                            dislplay: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                 
                    <Typography
                        sx={{
                            textAlign:'center',
                            color: '#3a3a3a',
                            fontSize: {xs:'32px', md: '40px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 500,
                        }}
                    >Fresh Clothes,</Typography>
                     <Typography
                        sx={{
                            textAlign:'center',
                            color: '#3a3a3a',                          
                            fontSize: {xs:'32px', md: '40px'},
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: 400,
                        }}
                    >Zero Hassle!</Typography>
                    <Box
                       >
                            <Box>
                                <img width={'150px'} src={logo} alt="Limitless Laundry" />
                            </Box>
                        <Typography
                            sx={{
                                color: '#00A5CF',
                                fontSize: {xs:'32px', md: '48px'},
                                fontFamily: 'Lilita One, sans serif',
                                fontWeight: 400,
                            }}
                        >LIMITLESS LAUNDRY</Typography>
                        <Typography
                            sx={{
                                color: '#F0C808',
                                fontSize: {xs:'18px', md: '24px'},
                                fontFamily: 'Lilita One, sans serif',
                                fontWeight: 500,
                            }}
                        >Endless Freshness Delivered</Typography>
                        <Box
                            sx ={{
                                width: '100%',
                                height: '100px',
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: {xs: 'space-evenly', md:'space-between'},
                                alignItems: {xs:'center'},
                            }}>
                            <Button
                                sx={{
                                    fontSize: '24px',
                                    backgroundColor: '#00A5CF',
                                    size: { xs: 'small', md:'small'},
                                    fontFamily: 'Montserrat, sans serif',
                                    fontWeight: '400',
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => { window.location.href ='https://quote.outscooped.com'}}
                                
                            >Sign Me Up</Button>
                            <Button
                                sx={{
                                    backgroundColor: '#3a3a3a',
                                    width: '120px',
                                    fontSize: '10px',
                                    size: { xs: 'small'},
                                    fontFamily: 'Montserrat, sans serif',
                                    fontWeight: '400',
                                }}
                                onClick={() => { window.location.href ='/#Services'}}
                                color="secondary"
                                variant="contained"
                                
                            >Learn More</Button>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box>
                <Typography sx={{
                    fontFamily: 'Montserrat, sans serif',
                    fontSize: {xs: '21px', sm: '28px', md:'42px'},
                    fontWeight: 400,
                    textAlign: 'center'
                }}>
                    Southern Arizona's Laundry Delivery Service
                </Typography>
            </Box>
            <Container
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: '-40px',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack
                spacing={2}
                useFlexGap
                sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
                >
                <Typography
                    variant="h1"
                    sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column' },
                    alignItems: 'center',
                    fontSize: {xs: '32px', md:'42px'},
                    fontFamily: '"Lilita One", sans serif',
                    fontWeight: 400,
                    }}
                >
                    More Time for What You Love,&nbsp;
                    <Typography
                    component="span"
                    variant="h1"
                    sx={(theme) => ({
                        fontSize: 'inherit',
                        color: '#00A5CF',
                        fontFamily: '"Lilita One", sans serif',
                        fontWeight: 400,
                        ...theme.applyStyles('dark', {
                        color: 'primary.light',
                        
                        }),
                    })}
                    >
                    Less Time on Laundry!
                    </Typography>
                </Typography>
                <Typography
                    sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    width: { sm: '100%', md: '80%' },
                    fontSize: { sm: '18px', md: '22px'},
                    fontFamily: 'Montserrat, sans serif',
                    
                    }}
                >
                    We take the dirty work off your hands, so you can focus on what really matters.
                </Typography>
                <Stack
                    direction={{ xs: 'column'}}
                    spacing={1}
                    useFlexGap
                    sx={{ pt: 2, width: { xs: '100%'} }}
                >
                    {/*<InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                    Email
                    </InputLabel>
                    <TextField
                    id="email-hero"
                    hiddenLabel
                    size="small"
                    variant="outlined"
                    aria-label="Enter your email address"
                    placeholder="Your email address"
                    fullWidth
                    slotProps={{
                        htmlInput: {
                        autoComplete: 'off',
                        'aria-label': 'Enter your email address',
                        },
                    }}
                    />*/}
                    <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ 
                        fontSize: '20px',
                        minWidth: 'fit-content',
                        backgroundColor: '#00A5CF',
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: '400',
                     }}
                     onClick={() => { window.location.href = '/signup'}}
                    >
                    Schedule Your First Pickup
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{
                            fontSize: '14px',
                            minWidth: 'fit-content',
                            backgroundColor: '#3a3a3a',
                            fontFamily: 'Montserrat, sans serif',
                            fontWeight: '400',
                        }}
                        onClick={() => { window.location.href ='/#Services'}}
                        >
                    Learn More
                    </Button>
                </Stack>
                </Stack>
                
            </Container>
        </>
    );
}

export default Hero;