import { Box, Container, Typography} from '@mui/material';
import * as React from 'react';

const SignUp = () => {

    return (
        <Container
        >
            <Box>
               <Typography
               sx={{
                font: 'Montserrat, sans-serif',
                mt: '120px',
                fontSize: '40px',
                fontWeight: 400,
                color: '#3a3a3a',
                mb: '80px',
               }}
               > 
                The webservice to sign up is not available at this time please call 520-557-4066 to set up a new account.
                </Typography>
            </Box>
        </Container>
    )
}

export default SignUp;