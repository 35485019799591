import * as React from 'react';
import AppTheme from './theme/AppTheme';
import { CssBaseline } from '@mui/material';
import Navbar from './Components/navbar/Navbar';
import Hero from './Components/Hero';
import Services from './Components/Services';
import About from './Components/About';
import Pricing from './Components/Pricing';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignUp from './Components/SignUp';
function App(props: {disableCustomTheme?: boolean}) {
  return (
    <BrowserRouter>

    <Routes>
      
      <Route path={'/signup'} element={
        <AppTheme {...props}>
          <CssBaseline enableColorScheme />
          <SignUp />
          <Pricing />
          <Footer />
          <Navbar />
        </AppTheme>}>
      </Route>
      <Route path={'*'} element={
        <AppTheme {...props}>
          <CssBaseline enableColorScheme />
          <Navbar />
          <Hero />
          <Services />
          <Pricing />
          <About />
          <FAQ />
          <Footer />
        </AppTheme>
        }>
      </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
