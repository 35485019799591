import { Box, Button, Card, CardActions, CardContent, Chip, Container, Divider, Grid2 as Grid, Typography } from '@mui/material';
import * as React from 'react';

const tiers = [
    {
        title: 'Twice a week Pick Up',
        price: '20.00',
        description: [
            'Our Best deal!',
            'Get 10% off your first service.',
            '2 bags included in subscription.'
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Once a week Pick Up',
        price: '24.00',
        description: [
            'Get 10% off your first pick up!','Hassle free laundry done each week.','2 bags included.'
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Twice a month Pick Up',
        price: '40.00',
        description: [
            'Great for apartments.','Smaller laundry needs.','3 bags included'
        ],
        buttonText: 'Sign Me Up'
    },
    {
        title: 'Once a month',
        price: '60.00',
        description: [
            '4 bags included.',
            '',
            ''
        ],
        buttonText: 'Sign Me Up'
    },
    {
      title: 'One Time Pick Up',
      price: '90.00',
      description: [
          '4 bags included.',
          '$12.00 for additial bags.',
          ''
      ],
      buttonText: 'Sign Me Up'
  }
];
const Pricing = () => {
    return (
        <Container id='Pricing'
        sx={{
        }}
        >
             <Box
        sx={{
          margin: '0 auto 20px auto',
          width: { xs: '100%', sm: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: '#3a3a3a', fontFamily: 'Montserrat, sans serif', fontWeight: 400, fontSize: '36px' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: '#3a3a3a', fontFamily: 'Montserrat, sans serif', fontSize: '18px' }}>
            One flat rate per Pick Up, there is a $9.00 dollar charge for each extra bag, and a $5.00 charge for stain treatment. There are no hidden fee's or delivery charges, tips are done at your own discretion but greatly appreciated.
        </Typography>
      </Box>
           <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 8, sm: 8, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '425px',
                },
                tier.title === 'Weekly Dog Poop Clean Up' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    },
                    tier.title === 'Weekly Dog Poop Clean Up'
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6" sx={{
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: '700',
                    width: '80%',
                    fontSize: '16px',
                    textAlign: 'center'
                  }}>
                    {tier.title}
                  </Typography>
                </Box>
                <Box
                  sx={[
                    {
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'center',
                    },
                    tier.title === 'Weekly Dog Poop Clean Up'
                      ? { color: 'grey.50' }
                      : { color: null },
                  ]}
                >
                    <Box sx={{ display: 'flex', flexDirection: {xs: 'column'}, alignItems: 'center'}}>
                        <Typography component="h3" variant="h2"
                            sx={{
                                color:"#6CAF46",
                                fontFamily: 'Montserrat, sans serif',
                                fontWieght: '600'
                            }}>
                            ${tier.price}
                        </Typography>
                        <Typography component="h3" variant="h6"
                            sx={{
                                fontFamily: 'Montserrat, sans serif',
                                fontWeight: 400,
                            }}
                        >
                            &nbsp; per Pick Up 
                        </Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
              </CardContent>
              <CardActions
                sx={{
                    justifyContent: 'space-between',
                    height: '100%',
                    flexDirection: 'column',
                }}
              >
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexWarp: 'no-wrap',
                }}>
                    {tier.description.map((text, i) => {
                        return (
                        <Typography sx={[
                            {
                            fontFamily: 'Montserrat, san serif',
                            fontWeight: '600',
                            width: '100%',
                            fontSize: '14px',
                            textAlign: 'left',
                            color: '#3a3a3a',
                            padding: 0,
                        },
                        tier.title == "Weekly Dog Poop Clean Up" &&
                        ((theme) => ({
                          color: '#fff',
                        }))
                        ]
                        } 
                        key={i} 
                        component={'span'}
                        >{text}</Typography>);
                    })}
                </Box>
                <Button
                  fullWidth
                  variant={'contained'}
                  sx={{backgroundColor:'#6CAF46', 
                    alignSelf: 'flex-end',
                  }}
                  onClick={()=>{ window.location.href = '/signup'}}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
        </Grid> 
        </Container>
    );
}

export default Pricing;