import * as React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography } from '@mui/material';

const faqs = [
    {
        category: 'General Questions',
        categoryFAQs: [
            {
                question: 'How does the service work?',
                answer: `It's simple! Schedule a pickup online or by phone, and we'll collect your laundry, wash, dry, and fold it, then deliver it back to your doorstep—clean and fresh!`,
            },
            {
                question: 'What areas do you serve?',
                answer: 'We currently serve the city of Maricopa, AZ. Check our website or contact us to confirm if we service your location.',
            },
            {
                question: 'How do I schedule a pickup?',
                answer: `You can schedule a pickup through our website or by phone. Just select a date that works for you, and we'll take care of the rest.`,
            },
            {
                question:'What types of laundry do you accept?',
                answer:'We handle everyday clothes only at this time. Items such as bedding, and delicates, will be additions available at a later time.',
            },
            {
                question: `How quickly will my laundry be returned?`,
                answer: `Our standard turnaround time is Next Day. Rush services are not available at this time.`
            },
            {
                question: `What happens if there’s an issue with my order?`,
                answer: `Your satisfaction is our priority. If there’s ever an issue with your order, please contact our customer support team immediately, and we’ll resolve it promptly.`
            }
        ]
    }
]
const FAQ = () => {
    const [expanded, setExpanded] = React.useState<string|false>(false);

    const handleChange =
        (panel: string)  => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        }
    return (
        <Container
            id="FAQ"
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: {xs: '100%', md: '60%'},
            }}
        >
            <Typography
                component="h2"
                variant='h4'
                sx={{
                    color: '#3a3a3a',
                    width: { sm: '100%', md: '80%'},
                    textAlign: { sm: 'left', md: 'center'},
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: '600',
                    fontSize: '36px',
                }}
            >
                Frequently Asked Questions
            </Typography>
            { faqs.map(({category, categoryFAQs}, index) => {
                return (
                    <>
                    <Box key={index} sx={{
                        fontFamily: 'Montserrat, sans serif',
                        fontWeight: 600,
                        fontSize: '24px',
                        pt: '48px',
                        pb: '10px',
                    }}>{category}</Box>
                    {categoryFAQs.map(({question, answer}, i) => {
                        return (
                            <Box key={i} sx={{ width: '100%'}}>
                                <Accordion
                                    expanded={expanded === `panel${index}${i}`}
                                    onChange={handleChange(`panel${index}${i}`)}
                                >
                                    <AccordionSummary 
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${index}${i}d-content`}
                                        id={`panel${index}${i}d-header`}
                                    >
                                        <Typography component='h3' variant='subtitle2'
                                            sx={{
                                                fontFamily: 'Montserrat, sans serif',
                                                fontWeight: 400
                                            }}>
                                            {question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant='body2'
                                            gutterBottom
                                            sx={{
                                                fontFamily: 'Montserrat, sans serif',
                                                fontWeight: 400,
                                                maxWidth: { sm: '100%', md: '70%'}
                                            }}
                                        >
                                           { answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    })}
                    </>
                )
            })}
            
            
        </Container>
    )
}

export default FAQ;