import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {Box, AppBar, Toolbar, Button, IconButton, Container, Divider, MenuItem, Drawer, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import logo from '../../Assets/logo no typography.png';

const pages = ['Services', 'About', 'Locations']

const StyledToolbar = styled(Toolbar)(({ theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  backdropFilter: 'blur(24px)',
  padding: '8px 48px',
  marginLeft: '-24px',
  marginRight: '-24px',
  
}));

const NavbarContent = (props:{}) => {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  }
  return (
    <AppBar
      position='fixed'
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height), 0px) + 28px'

      }}
      >
        <Container maxWidth='lg' >
          <StyledToolbar variant='dense' disableGutters >
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0}}>
              <Button onClick={() => {window.location.href='/'}}><img  src={logo} width="100px" alt='Outscooped' /></Button>
              <Box sx={{ display: { xs: 'none', md: 'flex', justifyContent: 'space-evenly'}}}>
                <Button variant='text' size='small' sx={{ color: '#3a3a3a', width: '100px', textAlign: 'center', fontFamily: 'Montserrat, sans serif', fontWeight: 600}}
                  onClick={() => { window.location.href = '/#Services' }}
                >
                  Services
                </Button>
                <Button variant='text' size='small'  sx={{ color: '#3a3a3a',width: '100px', textAlign: 'center', fontFamily: 'Montserrat, sans serif', fontWeight: 600}}
                  onClick={() => { window.location.href = '/#About'}}
                >
                  About
                </Button>
                {/* <Button variant='text' size='small'  sx={{ color: '#3a3a3a',width: '100px', textAlign: 'center'}}>
                  Testimonials
                </Button> */}
                <Button variant='text' size='small'  sx={{ color: '#3a3a3a',width: '100px', textAlign: 'center', fontFamily: 'Montserrat, sans serif', fontWeight: 600}}
                  onClick={() => {window.location.href = '/#Pricing' }}
                >
                  Pricing
                </Button>
                <Button variant='text' size='small'  sx={{ color: '#3a3a3a',width: '100px', textAlign: 'center', fontFamily: 'Montserrat, sans serif', fontWeight: 600}}
                onClick={() => { window.location.href = '/#FAQ'}}
                >
                  FAQ
                </Button>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: '#3a3a3a', width: '200px', textAlign: 'center', fontFamily: 'Montserrat, sans serif', fontWeight: 600, fontSize: '16px'
                }}
              >(520) 557-4066</Typography>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Button variant='text' size='small'
                sx={{
                  backgroundColor: '#F0C808',
                  color: '#3a3a3a',
                  fontFamily: 'Montserrat, sans serif', fontWeight: 600
                }}
                onClick={() => { window.location.href = '/signup'}}
              >
                Client Portal
              </Button>
              <Button variant="text" fullWidth sx={{
                    color: '#fff',
                    backgroundColor: '#00A5CF',
                    width: '100px',
                    fontSize: '12px',
                    fontFamily: 'Montserrat, sans serif', 
                    fontWeight: 400
                  }}
                  onClick={()=>{ window.location.href = '/signup'}}
                  >
                    Sign Me Up
                  </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>

                <MenuItem sx={{
                  fontFamily: 'Montserrat, sans serif',
                  fontWeight: 600,
                }} onClick={() => { window.location.href = '/#Services'}}
                >Services</MenuItem>
                <MenuItem sx={{
                  fontFamily: 'Montserrat, sans serif',
                  fontWeight: 600,
                }}
                onClick={() => { window.location.href = '/#About'}}>About</MenuItem>
                {/* <MenuItem>Testimonials</MenuItem> */}
                <MenuItem sx={{
                  fontFamily: 'Montserrat, sans serif',
                  fontWeight: 600,
                }}
                onClick={() => { window.location.href = '/#Pricing'}}>Pricing</MenuItem>
                <MenuItem sx={{
                  fontFamily: 'Montserrat, sans serif',
                  fontWeight: 600,
                }}
                onClick={() => { window.location.href = '/#FAQ'}}>FAQ</MenuItem>
                
                <Divider sx={{ my: 3 }} />
                <MenuItem>
                  <Button variant="contained" fullWidth sx={{
                    backgroundColor: '#F0C808',
                    color: '#fff',
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: 600,
                  }}
                  onClick={() => { window.location.href = '/signup'}}
                  >
                    Client Portal
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button variant="text" fullWidth sx={{
                    backgroundColor: '#00A5CF',
                    color: '#FFF',
                    fontFamily: 'Montserrat, sans serif',
                    fontWeight: 600,
                  }}
                  onClick={()=>{ window.location.href = '/signup'}}
                  >
                    Sign Me Up
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
          </StyledToolbar>
        </Container>
      </AppBar>
  );
}

const Navbar = (props: {}) => {
  return (
    <NavbarContent />
  )
}
export default Navbar;